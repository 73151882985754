import React from "react";

import {
  Checkbox,
  Divider,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { Loading } from "components/FullScreenLoading";
import Button from "components/ui/Button";
import { ButtonProps } from "components/ui/Button/Button";
import { SelectOption } from "components/ui/FormSelect";
import selectStyles from "components/ui/FormSelect/FormSelect.styles";
import InformationIcon from "components/ui/InformationIcon";
import { CheckablePair } from "components/ui/MultiSelect/MultiSelect";
import SvgIcon from "components/ui/SvgIcon";
import { coloredDot } from "shared/styles/styles";
import { classes, style } from "typestyle";

import styles from "./Body.styles";

export type MultiSelectOption = CheckablePair & SelectOption;

type MultiSelectBodyProps = {
  setSearch: (newValue: string) => void;
  filteredItems: MultiSelectOption[];
  toggleChecked: (item: MultiSelectOption) => void;
  isLoading?: boolean;
  buttons?: ButtonProps[];
  className?: string;
  title?: React.ReactNode;
  disableSearch?: boolean;
  onClose?: () => void;
};

// forwardRef required so it can be closed by other React components (eg.: <ClickawayListener/>)
const Body = React.forwardRef<HTMLDivElement, MultiSelectBodyProps>(
  (
    {
      filteredItems,
      setSearch,
      toggleChecked,
      isLoading,
      buttons,
      className,
      title,
      disableSearch,
      onClose,
    },
    ref
  ) => {
    return (
      <div className={classes(styles.dropdown, className)} ref={ref}>
        {title}
        {!disableSearch && (
          <Input
            disableUnderline
            className={styles.search}
            placeholder={"Search"}
            onChange={(event) => setSearch(event.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <SvgIcon src="/icons/search_icon.svg" className={styles.icon} />
              </InputAdornment>
            }
          />
        )}
        {isLoading ? (
          <Loading isWindowed />
        ) : (
          <List className={styles.list}>
            {filteredItems.map((item) => (
              <ListItem key={item.id}>
                <Checkbox
                  checked={item.checked}
                  onChange={() => toggleChecked(item)}
                />
                {item.color && (
                  <div
                    className={classes(
                      style(
                        coloredDot({
                          size: "10px",
                          color: item.color,
                          marginRight: "12px",
                          marginLeft: "8px",
                          flex: "0 0 10px",
                        })
                      )
                    )}
                  />
                )}
                <ListItemText
                  classes={{ root: style({ flex: "unset" }) }}
                  primary={item.name}
                />
                {item.info && (
                  <InformationIcon
                    title={item.info}
                    wrapperClassName={selectStyles.informationIconWrapper}
                    iconClassName={selectStyles.informationIcon}
                  />
                )}
              </ListItem>
            ))}
          </List>
        )}
        <Divider variant="middle" />
        <div
          className={classes(
            styles.buttons,
            ...(buttons?.length && buttons.length > 1
              ? [style({ justifyContent: "space-evenly" })]
              : [])
          )}
        >
          {buttons?.map((props, i) => (
            <Button
              key={i}
              {...props}
              onClick={(e) => {
                if (onClose) {
                  onClose();
                }
                if (props.onClick) {
                  props.onClick(e);
                }
              }}
            />
          ))}
        </div>
      </div>
    );
  }
);

export default Body;
