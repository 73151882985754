import React, { useContext } from "react";

import { DialogContent } from "@material-ui/core";
import Button from "components/ui/Button";
import FormSelect from "components/ui/FormSelect";
import ModalHeader from "components/ui/Modal/Header";
import SimpleModalBody from "components/ui/Modal/SimpleModalBody";
import TextArea from "components/ui/TextArea";
import ModalContext from "contexts/ModalContext";
import { useFormik } from "formik";
import { sendFeedBack } from "shared/client";
import { Feedback, FeedbackType, Pair } from "shared/model";

import styles from "./FeedbackModal.styles";

type FeedbackModalProps = {
  onClose: () => void;
};

const feedbackOptions: Pair[] = [
  {
    name: "Report an issue",
    id: "Issue",
  },
  {
    name: "Provide feedback",
    id: "Feedback",
  },
  {
    name: "Suggest a feature",
    id: "Suggestion",
  },
];

export default function FeedbackModal({ onClose }: FeedbackModalProps) {
  const { openModal, closeModal } = useContext(ModalContext);

  function handleClose() {
    onClose();
  }

  const initialValues: Feedback = {
    type: "" as FeedbackType,
    email: "",
    description: "",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      handleClose();
      await sendFeedBack(values);
      openModal(
        <SimpleModalBody
          title="Thanks for your feedback"
          buttons={[
            {
              variant: "normal",
              color: "orange",
              onClick: closeModal,
              children: "Ok, thanks",
            },
          ]}
        >
          By making your voice heard, you help us improve Pulse. You will
          receive an email shortly.
        </SimpleModalBody>
      );
    },
  });

  return (
    <div className={styles.modalContent}>
      <ModalHeader onClose={onClose}>Feedback</ModalHeader>
      <DialogContent className={styles.contentContainer}>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <p className={styles.paragraph}>
              Help us to improve, leave your feedback or any suggestion.
            </p>
            <div className={styles.inputContainer}>
              <label>Please, select an option</label>
              <FormSelect
                readOnlyInput
                options={feedbackOptions}
                placeholder="Select an option"
                value={formik.values.type}
                required={true}
                onChange={(option) => formik.setFieldValue("type", option)}
              />
            </div>
            <div className={styles.inputContainer}>
              <label>Description</label>
              <TextArea
                placeholder="Please, provide further details about your feedback."
                onChange={formik.handleChange("description")}
                required
              />
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <Button
              variant="normal"
              color="gray"
              onClick={handleClose}
              className={styles.cancelButton}
            >
              Cancel
            </Button>
            <Button variant="normal" color="orange" type="submit">
              Send Feedback
            </Button>
          </div>
        </form>
      </DialogContent>
    </div>
  );
}
